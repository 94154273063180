import React from 'react';
import { Helmet } from 'react-helmet-async';

const title = process.env.REACT_APP_HTML_TITLE ||
  'Repairy Booking';
const description = process.env.REACT_APP_HTML_DESC ||
  'Instant workshops booking';

export default () => {
  return (
    <Helmet>
      <script dangerouslySetInnerHTML={`
        var host = 'booking.repairy.com.au';
        if (
          host == window.location.host &&
          window.location.protocol != 'https:'
        ) {
          window.location.protocol = 'https';
        }
      `}
      />
      <title>{title}</title>
      <meta name="description" content={description} />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon.ico"
      />
      <link
        rel="mask-icon"
        href="/safari-pinned-tab.svg"
        color="#4e4e4e"
      />
      <meta name="msapplication-TileColor" content="#f8f6ee" />
      <meta name="theme-color" content="#ffffff" />
    </Helmet>
  );
}