import React from 'react';

import HtmlHead from '../../components/BookingApp/HtmlHead';

// available themes
import css from './Theme.module.css';
import repairy from './themes/repairy.module.css';
import tyreplus from './themes/tyreplus.module.css';

const Themes = { repairy, tyreplus };
const themeName = process.env.REACT_APP_THEME || 'repairy';

const theme = Themes[themeName];

const ThemeContainer = ({children}) => (
	<div className={[theme.colours, css.App].join(' ')}>
		<HtmlHead />
		{children}
	</div>
);

export default ThemeContainer;
