import React from 'react';
import model from './models/WorkshopModel';
import { createStore } from 'easy-peasy';

const isDevToolsActive = (
  process.env.REACT_APP_DEVTOOLS ||
  process.env.NODE_ENV !== 'production'
)

const store = createStore(model, {
  devTools: isDevToolsActive,
});
const context = React.createContext({ store });

export default context;
