import axios from 'axios';
import moment from 'moment-timezone';
import { toast } from "react-toastify";
import smartlookClient from 'smartlook-client';
import { action, persist, thunk } from 'easy-peasy';

import { delay } from '../utils/extraMethods';

const trackEvent = process.env.REACT_APP_SMARTLOOK_ID;

const initialState = {
  booking: {
    code: null,
    workshopId: null,
    jobIds: [],
    mainJobId: null,
    customerFirstName: null,
    customerLastName: null,
    customerEmail: null,
    customerPhone: null,
    customerCompany: null,
    customerAddress: null,
    customerSuburb: null,
    customerPostcode: null,
    carRego: null,
    bookingTime: null,
    serviceInterval: null,
    bookingNote: null,
    bookingOtherNotes: null,
    status: null,
    pickupTime: null,
    tyreWheelJobCode: null,
    tyreWheelJobNotes: null,
    servicePackageType: null,
  },
  store: {
    car: null,
    loading: false,
    workshop: null,
    linkName: null,
    reminder: null,
    customer: null,
    fetching: false,
    lastService: null,
    blackhole: false,
  }
}

const BookingModel = {
  ...initialState.booking,
  setBookingCustomerAndCar: action((state, payload) => {
    state.customerFirstName = payload.firstName;
    state.customerLastName = payload.lastName;
    state.customerEmail = payload.email;
    state.customerPhone = payload.mobile;
    state.carRego = payload.carRego;

    if (payload.code) {
      state.code = payload.code;
      state.status = payload.status;
      state.bookingNote = payload.notes;
      state.bookingOtherNotes = payload.otherNotes;
      state.workshopId = payload.workshopId;
      state.serviceInterval = payload.interval;
      state.bookingTime = moment(payload.date);
      state.pickupTime = payload.pickupTime
        ? moment(payload.pickupTime)
        : null;
      if (payload.servicePackageType === 'basic') {
        state.mainJobId = 1
      } else if (payload.servicePackageType === 'others') {
        state.mainJobId = 2
      } else if (payload.servicePackageType === 'tyres') {
        state.mainJobId = 3
      } else {
        state.mainJobId = 0
      }
    }
    state.servicePackageType = payload.servicePackageType;
    state.customerCompany = payload.customerCompany;
    state.customerAddress = payload.customerAddress;
    state.customerSuburb = payload.customerSuburb;
    state.customerPostcode = payload.customerPostcode;
  }),
  setMainJobId: action((state, payload) => {
    state.mainJobId = payload;
    state.jobIds.splice(0, 1, payload);
  }),
  setBookingState: action((state, payload) => {
    state = payload;
  }),
  setServiceInterval: action((state, payload) => {
    state.serviceInterval = payload;
  }),
  setBookingTime: action((state, payload) => {
    state.bookingTime = payload;
  }),
  setWorkshopId: action((state, payload) => {
  state.workshopId = payload;
  }),
  setBookingNote: action((state, payload)=>{
    state.bookingNote = payload
  }),
  setBookingOtherNotes: action((state, payload)=>{
    if (payload && !Array.isArray(payload)) {
      state.bookingOtherNotes = [payload]
    } else {
      state.bookingOtherNotes = payload
    }
  }),
  setStatus: action((state, payload) => {
    state.status = payload;
  }),
  setPickupTime: action((state, payload) => {
    state.pickupTime = payload;
  }),
  setTyreWheelJobCode: action((state, payload) => {
    state.tyreWheelJobCode = payload;
  }),
  setTyreWheelJobNotes: action((state, payload) => {
    state.tyreWheelJobNotes = payload;
  }),
  submitBooking: thunk(async (actions, payload, helper) => {
    try {
      helper.getStoreActions().setLoading(true);
      const reminder = helper.getStoreState().reminder;
      const currBooking = helper.getStoreState().booking;
      const currWorkshop = helper.getStoreState().workshop;
      const {
        customerEmail,
        customerFirstName,
        customerLastName,
        customerPhone,
        carRego,
        bookingTime,
        mainJobId,
        serviceInterval,
        bookingNote,
        bookingOtherNotes,
        customerCompany,
        pickupTime,
        tyreWheelJobCode,
        tyreWheelJobNotes,
      } = currBooking;

      // MainJobId:
      // 0 -> logbook
      // 1 -> basic
      // 2 -> others
      // 3 -> tyres and wheels
      let servicePackageType = 'logbook';
      if (mainJobId === 1) {
        servicePackageType = 'basic';
      } else if (mainJobId === 2) {
        servicePackageType = 'others';
      } else if (mainJobId === 3) {
        servicePackageType = 'tyres';
      }
      // sanitize tz
      const zone = currWorkshop.zone;
      const format = 'YYYY-MM-DD HH:mm:ss';
      const bookingTimeStr = moment(bookingTime).format(format);
      const pickupTimeStr = moment(pickupTime).format(format);

      const bookingTimeUTC = moment.tz(bookingTimeStr, format, zone).utc();
      const pickupTimeUTC = moment.tz(pickupTimeStr, format, zone).utc();
      const data = {
        workshopId: currWorkshop._id,
        customerEmail: customerEmail,
        carRego: carRego,
        bookingTime: bookingTimeUTC.format(),
        customerName: [customerFirstName, customerLastName].join(' '),
        customerPhone,
        notes: bookingNote,
        otherNotes: bookingOtherNotes,
        servicePackageType,
        reminderCode: reminder ? reminder.code : null,
        serviceInterval: mainJobId === 0 ? serviceInterval : null,
        customerCompany,
        customerAddress: currBooking.customerAddress,
        customerSuburb: currBooking.customerSuburb,
        customerPostcode: currBooking.customerPostcode,
        pickupTime: pickupTimeUTC.format(),
        tyreWheelJobCode,
        tyreWheelJobNotes,
      };
      const ret = await axios({
        method: 'post',
        url: '/bookings',
        responseType: 'json',
        data: data,
      });
      if (trackEvent) smartlookClient.track('submitBooking', data);
      await delay(1000);
      helper.getStoreActions().setLoading(false);
      const {booking} = ret.data
      helper.getStoreActions().toast({
        type: 'success',
        text: `Booking request #${booking.code} created successfully`,
      });
      return ret.status === 200 || ret.status === 201;
    } catch (error) {
      if (trackEvent) smartlookClient.error(error);
      helper.getStoreActions().setLoading(false);
      // handle other error message
      if (error.response && error.response.data) {
        const {data = {}} = error.response.data
        if (data.reason === 'lead-time' && data.leadTime) {
          const leadTime = moment(data.leadTime);
          helper.getStoreActions().toast({
            type: 'error',
            text: 'Sorry, kindly reschedule since we only accept booking after ' +
            leadTime.format('DD MMM HH:mmA'),
          });
        }
        return;
      }
      helper.getStoreActions().toast({
        type: 'error',
        text: 'Sorry, we have problem booking you in. Please try again or contact support',
      });
    }
  }),
  reset: action((state, payload) => {
    Object.keys(initialState.booking).map(key => {
      state[key] = initialState.booking[key];
      return true;
    });
  }),
};

const StoreModel = persist({
  ...initialState.store,
  booking: BookingModel,
  setBlackhole: action((state, payload) => {
    state.blackhole = payload;
  }),
  lookupReminder: thunk(
    async (
      actions,
      payload,
      { getStoreState, getStoreActions }
    ) => {
      const {
        car,
        code,
        service,
        reminder,
        customer,
        workshop,
      } = payload;
      actions.configureState({
        car,
        customer,
        reminder,
        workshop,
        linkName: code,
        lastService: service,
      });
      getStoreActions().booking.setBookingCustomerAndCar({
        carRego: car.rego,
        email: customer.email,
        mobile: customer.phone,
        lastName: customer.lastName,
        firstName: customer.firstName,
        customerCompany: customer.company,
        customerAddress: customer.address,
        customerSuburb: customer.suburb,
        customerPostcode: customer.postcode,
      });
      // set next interval, right now its using interval
      if (getStoreState().booking.mainJobId === 0) {
        const intervalGapKms = 10000;
        const nextInterval = parseInt(service.interval) + intervalGapKms;
        getStoreActions().booking.setServiceInterval(nextInterval);
      } else {
        getStoreActions().booking.setServiceInterval(null);
      }
      if (trackEvent) {
        smartlookClient.identify(customer._id, {
          email: customer.email,
          name: customer.firstName + ' ' + customer.lastName,
        });
      }
      return customer;
  }),
  initStore: thunk(async (actions, code) => {
    try {
      actions.setBlackhole(false);
      actions.setLoading(true);
      if (trackEvent) smartlookClient.init(process.env.REACT_APP_SMARTLOOK_ID);
      const result = await axios({
        method: 'get',
        responseType: 'json',
        url: '/sdk/bookings/' + code,
      });

      if (!result || !result.data) {
        // TODO: handle error
        actions.setLoading(false);
        return;
      };

      const {
        workshop,
        reminderMode,
      } = result.data;

      // handle reminder lookup
      if (reminderMode) {
        actions.lookupReminder({
          code,
          ...result.data
        });
        actions.setLoading(false);
        return;
      }

      // handle booking lookup
      actions.setLoading(false);

      // config
      let shopConfig = {}
      if (workshop) {
        const legacy = { ...workshop.config }
        shopConfig = {
          ...legacy,
        }
        // support for config v2
        const configV2 = { ...workshop.settings }
        if (configV2.configVersion === 'v2') {
          shopConfig = {
            ...shopConfig,
            ...configV2,
          }
        }
        // console.log('shopConfig', {legacy, configV2, shopConfig})
      }

      actions.configureState({
        workshop,
        linkName: code,
        config: shopConfig,
      });
    } catch (error){
      console.log(error);
      actions.setBlackhole(true);
      actions.setLoading(false);
    } finally {
      if (trackEvent) smartlookClient.anonymize();
    }
  }),
  configureState: action((state, payload) => {
    state.booking.workshopId = payload.workshop._id;
    state.workshop = payload.workshop;
    if (state.workshop && payload.config) {
      state.workshop.config = payload.config;
    }
    state.linkName = payload.linkName;
    // returning customer
    state.car = payload.car;
    state.customer = payload.customer;
    state.reminder = payload.reminder;
    state.lastService = payload.lastService;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload; // boolean
  }),
  toast: action((state, payload) => {
    if (!toast[payload.type]) return;
    toast[payload.type](payload.text, payload.opts);
  }),

  capBookings: {},
  suspensions: {},
  suspensionWatch: moment(),
  setSuspensionWatch: action((state, date) => {
    state.suspensionWatch = moment(date);
  }),
  setSuspensions: action((state, payload = {}) => {
    const {zone, items, caps} = payload
    // suspensions
    const suspensions = {};
    items.map(item => {
      const date = moment(item.date).tz(zone).format('YYYYMMDD')
      suspensions[date] = item
      return item
    })
    state.suspensions = suspensions;
    // booking caps
    const capBookings = {};
    caps.map(item => {
      const date = moment(item.date).tz(zone).format('YYYYMMDD')
      capBookings[date] = item
      return item
    })
    state.capBookings = capBookings;
  }),
  fetchSuspensionSchedule: thunk(
    async (actions, payload, {
      getStoreActions,
      getStoreState
    }) => {
      try {
        let targetDate = payload;
        if (payload && payload.silent) {
          targetDate = payload.date;
        }
        if (!payload.silent) {
          getStoreActions().setLoading(true);
          await delay('1000');
        }
        const workshop = getStoreState().workshop;
        const date = moment(targetDate);
        const response = await axios({
          method: 'get',
          url: `/workshops/${workshop._id}/bookings/suspensions`,
          responseType: 'json',
          params: {
            year: date.year(),
            month: date.month() + 1,
            tz: moment.tz.guess(),
          }
        });
        if (!response.data || !response.data.suspensions) {
          throw new Error('Failed to fetch workshop suspension schedule');
        }

        const capResponse = await axios({
          method: 'get',
          url: `/workshops/${workshop._id}/bookings/caps`,
          responseType: 'json',
          params: {
            year: date.year(),
            month: date.month() + 1,
            tz: moment.tz.guess(),
          }
        });
        if (!capResponse.data || !capResponse.data.capBookings) {
          throw new Error('Failed to fetch booking caps summary');
        }

        actions.setSuspensions({
          zone: workshop.zone,
          items: response.data.suspensions,
          caps: capResponse.data.capBookings,
        });
        actions.setSuspensionWatch(date);
      } catch (error) {
        console.error(error);
      } finally {
        if (payload && !payload.silent) {
          getStoreActions().setLoading(false);
        }
      }
    }
  ),
  setWorkshop: action((state, payload) => {
    state.workshop = payload;
  }),
  setFetching: action((state, payload) => {
    state.fetching = payload;
  }),
  reset: action((state, payload) => {
    Object.keys(initialState.store).map(key => {
      state[key] = initialState.store[key];
      return true;
    });
  }),
  initTracking: thunk(async (actions, payload, { getStoreActions }) => {
    try {
      const {
        code,
        track,
        version,
        workshopCode,
        bookingShortCode,
      } = payload;

      actions.reset();
      getStoreActions().booking.reset();

      actions.setFetching(true);
      smartlookClient.init(process.env.REACT_APP_SMARTLOOK_ID);

      const lookupURL = version === 2
        ? '/workshops/' + workshopCode + '/bookings/' + bookingShortCode
        : '/bookings/' + code;

      const result = await axios({
        method: 'get',
        responseType: 'json',
        url: lookupURL,
        params: {
          tr: track,
          v: version,
        },
      });

      if (result && result.data) {
        const { booking } = result.data;
        const { customer, car, workshop } = booking;
        actions.setWorkshop(workshop);
        getStoreActions().booking.setBookingCustomerAndCar(Object.assign(
          {
            carRego: car.rego,
            email: customer.email,
            mobile: customer.phone,
            lastName: customer.lastName,
            firstName: customer.firstName,
            workshopId: booking.workshopId,
            customerCompany: customer.company,
            customerAddress: customer.address,
            customerSuburb: customer.suburb,
            customerPostcode: customer.postcode,
          },
          booking
        ));
      }
    } catch (error) {
      console.error(error);
      smartlookClient.error(error);
    } finally {
      actions.setFetching(false);
    }
  }),
});

export default StoreModel;
