import React, { useContext } from 'react';
import { StoreProvider } from 'easy-peasy';
import loadable from '@loadable/component';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import AppContext from './AppContext';
import ThemeContainer from './containers/ThemeContainer';

const BookingApp = loadable(() =>
import ('./containers/BookingApp/BookingApp')
);
const BookingAppStatus = loadable(() =>
import ('./containers/BookingApp/BookingAppStatus')
);

function App() {
	const { store } = useContext(AppContext);

	return (
		<HelmetProvider>
			<StoreProvider store={store}>
				<BrowserRouter>
					<Switch>
						<Route path="/s/:bookingCode/">
							<ThemeContainer>
								<BookingAppStatus />
							</ThemeContainer>
						</Route>
						<Route path="/f/:bookingCode/">
							<ThemeContainer>
								<BookingAppStatus review />
							</ThemeContainer>
						</Route>
						<Route path="/:workshopCode/">
							<ThemeContainer>
								<BookingApp />
							</ThemeContainer>
						</Route>
						<Route
							path="/"
							exact
							component={() => {
								window.location.href = 'https://www.repairy.com.au/';
								return null;
							}}
						></Route>
					</Switch>
				</BrowserRouter>
			</StoreProvider>
		</HelmetProvider>
	);
}

export default App;
