import { useState } from 'react';
import moment from 'moment-timezone';

export const delay = (t, v) => {
	return new Promise(function (resolve) {
		setTimeout(resolve.bind(null, v), t);
	});
};

export const dateParse = (date) => {
	const d = new Date(date);
	return (
		dateToDayParse(d.getDay()) +
		', ' +
		d.getDate() +
		'/' +
		(d.getMonth() + 1) +
		'/' +
		d.getFullYear()
	);
};

export const timeParse = (date) => {
	return moment(date).format('h:mm A');
};

export const dateToDayParse = (day) => {
	const weekdays = [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
	];

	return weekdays[day];
};

export const useLocalStorage = (key, initialValue) => {
	// State to store our value
	// Pass initial state function to useState so logic is only executed once
	const [storedValue, setStoredValue] = useState(() => {
		try {
			// Get from local storage by key
			const item = window.localStorage.getItem(key);
			
			// Parse stored json or if none return initialValue
			return item ? JSON.parse(item) : initialValue;
		} catch (error) {
			// If error also return initialValue
			console.log(error);
			return initialValue;
		}
	});

	// Return a wrapped version of useState's setter function that ...
	// ... persists the new value to localStorage.
	const setValue = (value) => {
		try {
			// Allow value to be a function so we have same API as useState
			const valueToStore =
				value instanceof Function ? value(storedValue) : value;
			// Save state
			setStoredValue(valueToStore);
			// Save to local storage
			window.localStorage.setItem(key, JSON.stringify(valueToStore));
		} catch (error) {
			// A more advanced implementation would handle the error case
			console.log(error);
		}
	};

	return [storedValue, setValue];
};

export const numberParse = (num) => {
	const val = parseInt(num);
	if (isNaN(val)) return '-';
	return String(val).replace(/(.)(?=(\d{3})+$)/g,'$1,')
}

export const capitalize = (text, firstOnly) => {
  if (!text) return text
  text += ''
  if (firstOnly) {
    return text
      .trim()
      .toLowerCase()
      .replace(/^\w/, (c) => c.toUpperCase())
  }
  return text
    .trim()
    .toLowerCase()
    .replace(
      /\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))
    )
}

export const TyreWheelJobMap = {
	't02': 'Tyre Purchase',
	'w01': 'Wheel Alignment',
	'w02': 'Wheel Balancing',
	't01': 'Puncture Repair',
}

export const CDNBaseURL = process.env.REACT_APP_CDN_URL + '/icons';

/**
 * use latest most schedule (sequence descending)
 * e.g:
 *    0 -> { sequence: 123, days: [6,0], open: 10, ... }
 *    1 -> { sequence: 456, days: [0], open: 12, ... }
 *    verdict ->
 *      {
 *        6: { open: 10, sequence: 123, ... },
 *        0: { open: 12, sequence: 456, ... }
 *      }
 */
 export const getCustomSchedules = (config) => {
  const compiled = {}
  if (!config) return compiled
  const { customSchedules: schedules = []} = config
  if (schedules.length < 1) return compiled
  schedules.forEach(schedule => {
    const {days = []} = schedule
    if (days.length < 1) return
    days.forEach(rawDay => {
      const day = parseInt(rawDay)
      if (
        !compiled[day] ||
        // bigger sequence takes priority
        (
          compiled[day] &&
          compiled[day].sequence < schedule.sequence
        )
      ) {
        compiled[day] = { ...schedule }
        // cleanup
        delete compiled[day].days
      }
    })
  })
  return compiled
}

const bookingCallbackWhitelist = (
  process.env.REACT_APP_BOOKING_CALLBACK || ''
).split('||')
export const isBookingCallbackEnabled = (code) => {
  if (bookingCallbackWhitelist === 'ALL') return true;
  else if (!code || bookingCallbackWhitelist.length < 1) return;
  else if (bookingCallbackWhitelist.includes(code)) return true;
  return;
};
