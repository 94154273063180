import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';


const configAxios = async () => {
	const token = process.env.REACT_APP_KEY;
	axios.defaults.headers['X-App-Key'] = token;
	axios.defaults.baseURL = process.env.REACT_APP_API_URL;
}

const init = async () => {
  await configAxios();

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
  
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
};

// init app
init();
